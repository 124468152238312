/* Define the mobile preview styles */
.mobile-preview {
	width: 375px; /* typical mobile width */
	margin: 0 auto; /* center the mobile preview */
}

/* Apply the mobile preview styles when the screen is larger than 768px (tablet size) */
@media (min-width: 768px) {
	.mobile-preview {
		width: 512px; /* typical mobile width */
		margin: 0 auto; /* center the mobile preview */
	}

	.mobile-preview-container {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh; /* optional: make container full height */
	}
}
