//
// Custom
//

@use 'sass:map';

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.rdt_TableCell div {
	white-space: normal !important;
	overflow: visible !important;
}

.rdt_TableCol_Sortable span:last-child {
	display: none;
}

.rdt_TableCol_Sortable:hover span:last-child {
	display: block;
}

.list-group-flush.list-group-horizontal > .list-group-item {
	border-bottom-width: 1px;
	border-top-width: 0;
}

.list-group-flush.list-group-horizontal:last-child > .list-group-item {
	border-bottom-width: 0;
}

.list-group-flush.list-group-horizontal.items-2 > .list-group-item {
	min-width: 50%;
}

.input-group-text svg[data-name="Bootstrap--EyeFill"]:hover {
	cursor: pointer;
	opacity: 50%;
}

.input-group-text svg[data-name="Bootstrap--EyeSlashFill"]:hover {
	cursor: pointer;
	opacity: 50%;
}

.timeline::before {
	background-color: #adb5bd4d;
    border-radius: 1rem;
    bottom: 0;
    content: "";
    left: calc(var(--width-label, 5.5rem) + 0.5rem + 4px);
    position: absolute;
    top: .5rem;
    width: 3px;
    z-index: 1;
}

.timeline .timeline-label 
{
	max-width: calc(var(--width-label, 5.5rem));
	width: 100%;
}