//
//	Modal
//

.modal-content {
	@include dark-mode {
		background-color: $dark-modal-content-bg;
	}
}

.modal {
	&-backdrop {
		@include backdrop-filter-blur($modal-backdrop--blur);

		background-color: rgba($modal-backdrop-bg, $modal-backdrop-opacity);

		&.show {
			opacity: 1;
		}
	}
}

@media (min-width: 576px) {
	.modal-xxl {
		min-width: 576px;
	}
}

@media (min-width: 768px) {
	.modal-xxl {
		min-width: 768px;
	}
}

@media (min-width: 992px) {
	.modal-xxl {
		min-width: 992px;
	}
}

@media (min-width: 1200px) {
	.modal-xxl {
		min-width: 1200px;
	}
}

@media (min-width: 1400px) {
	.modal-xxl {
		min-width: 1400px;
	}
}

@media (min-width: 1600px) {
	.modal-xxl {
		min-width: 1600px;
	}
}